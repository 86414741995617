
import { defineComponent, onMounted, reactive, watch } from "vue";
import * as moment from "moment";
import mAxiosApi from "@/api";
import XLSX from "xlsx";
import { ElNotification } from 'element-plus'
import { useRouter } from "vue-router";

export default defineComponent({
  name: "contrats",
  components: {
    //
  },
 
  emits: ["refreshRouter"],

  setup(props, { emit }) {

    const router = useRouter();
    //moment.default.locale("fr");

    const form = reactive({
      affNonImportable : true,
      affPersPhys : true,
      affPersMorale : true,
    });

    const state = reactive({
      etape: 0,
      workbook: [] as any,
      workbookDisplay: [] as any,
      okList : false,
      totIncoherence : 0 as number,
      lConst : [] as any,
      loaderUpload : false,
    });

    const submitUpload = () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];
      const reader = new FileReader();
      
      reader.addEventListener("load", function () {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        const zWorkbook = XLSX.read(data, {type: 'array'});
        state.workbook = to_json(zWorkbook)['Sheet1'];
        test_coherence();


      }, false);

      if (file) {
        reader.readAsArrayBuffer(file);
      }

    }

    const excelToDate = (excelDate) => {
      const mDate = new Date(Math.round((excelDate - (25567 + 2)) * 86400 * 1000));
      if (isNaN(mDate.getTime())) {
        return '';
      } 
      const convertedDate = mDate.toISOString().split('T')[0];
      return moment.default(convertedDate).format("DD.MM.YYYY");
    }

    const to_json = function to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function(sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1});
        if(roa.length) result[sheetName] = roa;
      });
      return result;
    };

    const test_coherence = () => {
      state.totIncoherence = 0;
      state.workbook = state.workbook.filter(item => item.length > 0);
      let totIncoherence = 0;

      if (state.workbook[0] != "Export des producteurs et fournisseurs") {
          ElNotification({ title: 'Erreur', message: "L'entête du fichier (Ligne 1) semble incorrecte", type: 'error', })
          state.loaderUpload = false;
          return false;
      }
      delete state.workbook[0];
      delete state.workbook[1];

      state.workbook.forEach(function(item) {
        
        item[29] = [] as any;       
        if ( !parseInt(item[0]) ) item[29].push('Code incorrect');

        if ( returnType(item[1]) == "Type inconnu"  ) item[29].push('Type incorrect');
      
        //if ( item[1] != 2 ) {
          // Les seuls codes pouvant être concernés par les versements sont : 1, 2, 3, 5, 6
          if (item[2] == 4 ) item[29].push('Type non importable');
          if (item[2] == 7 ) item[29].push('Type non importable');
          if (item[2] == 8 ) item[29].push('Type non importable');
          if (item[2] == 9 ) item[29].push('Type non importable');
        //} 
       
      });
      
      state.totIncoherence = totIncoherence;
      state.loaderUpload = false;
      computedWorkbook();
      state.okList = true;
      state.etape = 1;

    };

    watch(form, () => {
      computedWorkbook();
    });


    const computedWorkbook = () => {
      state.okList = false;
      state.workbookDisplay = state.workbook

      if (!form.affNonImportable) {
        state.workbookDisplay = state.workbookDisplay.filter(
          (item: any) => item[29].length == 0);
      }

      if (!form.affPersMorale) {
        state.workbookDisplay = state.workbookDisplay.filter(
          (item: any) => item[1] != 1 && item[1] != 3 );
      }

      if (!form.affPersPhys) {
        state.workbookDisplay = state.workbookDisplay.filter(
          (item: any) => item[1] != 2 );
      }
      state.okList = true;
    }

    const tableRowClassName = ({ row }: {
      row: any
    }) => {
      if (row[29].length > 0) return 'warning-row'
      return ''
    }

    const totDefaut = (mArray) => {
      let test = 0;
      mArray.forEach(function(item) {
        if(item[0]) test += 1;
      });
      return test;
    }

    const importActeurs = async () => {
      state.etape += 1;
      const workbook: any = state.workbook;
      mAxiosApi
        .post("importActeurs", { workbook })
        .then((data) => {

          ElNotification({
            title: 'Succès',
            message: 'Import réalisé avec succès !',
            type: 'success',
          })

          router.push({ name: "admin" });
          
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const returnType = (type) => {
      let mType = ""
      switch (type) {
        case 1:
          mType = "Collectivité"
          break;
        case 2:
          mType = "Personne"
          break;
        case 3:
          mType = "Famille"
          break;
        default:
          mType = "Type inconnu"
      }
      return mType;
    }

    const returnCateg = (type) => {
      let mCateg = ""
      switch (type) {
        case 1:
          mCateg = "Administration"
          break;
        case 2:
          mCateg = "Collectivité territoriale"
          break;
        case 3:
          mCateg = "Etablissement public"
          break;
        case 4:
          mCateg = "Personne physique"
          break;
        case 5:
          mCateg = "Officier public"
          break;
        case 6:
          mCateg = "Entreprise publique"
          break;
        case 7:
          mCateg = "Entreprise privée"
          break;
        case 8:
          mCateg = "Association"
          break;
        case 9:
          mCateg = "Famille"
          break;
        default:
          mCateg = "Catégorie inconnu"
      }
      return mCateg;
    }

    const returnMultiAdr = (row) => {
      if (row) return row.split(" $ ");
      return [];
    }

    onMounted(async () => {
      //state.lConst = await getAxios("/getConstList");
      // console.log('Contructeur', myList);
    });


    return {
      state,
      form,
      submitUpload,
      excelToDate,
      test_coherence,
      totDefaut,
      importActeurs,
      returnType,
      returnCateg,
      returnMultiAdr,
      tableRowClassName,
      computedWorkbook
    };
  
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
